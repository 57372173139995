export default {
  name: "ticketStep",
  data() {
    return {
      buttonLoad: false,
      buttonLoadtwo: false,
      formData: {
        name: '',
        workNumber: ''
      }
    };
  },
  created() {},
  methods: {
    close() {
      this.$emit('close', true);
    },
    async loginClick() {
      console.log("ssss");
      if (!this.formData.name) {
        console.log("ss");
        this.$toast({
          message: '请输入姓名'
        });
        return false;
      }
      if (!this.formData.workNumber) {
        this.$toast({
          message: '请输入工号'
        });
        return false;
      }
      this.buttonLoadtwo = true;
      let result = await this.$request({
        method: 'post',
        url: '/app/auth/info',
        data: {
          name: this.formData.name,
          workNumber: this.formData.workNumber
        }
      });
      console.log(result);
      this.buttonLoadtwo = false;
      if (result.code == 0) {
        this.$toast({
          message: '认证成功'
        });
        this.$store.commit('setUserInfo', result.data);
        this.$store.dispatch('getIntegralCurrency');
        // this.$router.back()
      } else {
        this.$toast({
          message: result.message
        });
      }
    }
  }
};
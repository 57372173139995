import "core-js/modules/es.array.push.js";
import certificationComp from '../../components/certification-comp';
export default {
  name: 'shop',
  components: {
    certificationComp
  },
  data() {
    return {
      certificationAuto: false,
      goodData: {}
    };
  },
  async created() {},
  async mounted() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      let {
        id
      } = this.$route.params;
      let result = await this.$request({
        method: 'get',
        url: '/app/api/shop/good/' + id
      });
      console.log(result);
      if (result.code == 0) {
        this.goodData = result.data;
      }
    },
    async change() {
      if (this.$store.state.userInfo.company == false) {
        this.certificationAuto = true;
        return false;
      }
      this.$router.push('/orderAdd/' + this.goodData.id);
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "info-div"
  }, [_c('div', {
    staticClass: "banner"
  }, [_c('van-image', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "fit": "cover",
      "src": _vm.goodData.cover
    }
  })], 1), _c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goodData.name))]), _c('div', {
    staticClass: "botton-box"
  }, [_c('div', {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm.goodData.integral ? `${_vm.goodData.integral}积分` : '') + _vm._s(_vm.goodData.integral && _vm.goodData.price ? '+' : '') + _vm._s(_vm.goodData.price ? `${_vm.goodData.price}元` : ''))]), _c('div', {
    staticClass: "tag"
  }, [_vm._v(_vm._s((_vm.goodData.integral ? '积分' : '') + (_vm.goodData.integral && _vm.goodData.price ? '+' : '') + (_vm.goodData.price ? '现金' : '')) + "兑换")])])]), _c('div', {
    staticClass: "detail-div"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("商品详情")]), _c('div', {
    staticClass: "detail"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.goodData.detail)
    }
  })])]), _c('foot-bar-box-comp', [_c('div', {
    staticClass: "exchange-button-div"
  }, [_c('div', {
    staticClass: "exchange-button",
    on: {
      "click": _vm.change
    }
  }, [_vm._v("立即兑换")])])]), _vm.certificationAuto && !_vm.$store.state.userInfo.company ? _c('certification-comp', {
    on: {
      "close": function ($event) {
        _vm.certificationAuto = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };